import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Section from '~components/Section'
import Seo from "~components/Seo"
import { graphql } from 'gatsby';
import LocationBlock from '~components/blocks/LocationBlock'
import Button from '~components/blocks/Button'
import CTASection from '~components/sections/CTASection'
import resolveLink from '~utils/resolveLink'

const Showrooms = ( data ) => {

  const {mobile} = breakpoints

  const showroomPage = data?.data?.sanityShowrooms
  const showrooms = showroomPage._rawContent.showrooms

	console.log({showrooms})

  return (
    <div>
      <Seo
        title={showroomPage?.title}
        image={showroomPage?.content?.seo?.socialImage?.asset?.url}
        description={showroomPage?.content?.seo?.metaDescription}
        metaTitle={showroomPage?.content?.seo?.metaTitle}
				url="/showrooms"
      />
      <div css={css`
        position: sticky;
        top: 100vh;
        transform: translateY(calc(100% - 58px));
        white-space: nowrap;
        left: 40px;
        height: 0;
        width: 0;
        letter-spacing: 0.1em;
        ${mobile}{
          display: none;
        }
      `}>
        <div css={css`
          position: absolute;
          bottom: 0px;
        `}>
          <Button to={`/contact`}>Contact Us</Button>
        </div>
      </div>
      <Section css={css`
        padding-top: 22px;
        ${mobile}{
          padding-top: 16px;
        }
      `}>
        <div css={css`
          grid-column: 1/3;
          ${mobile}{
            grid-column: span 12;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 40px;
          }
        `}>
          <h4>{showroomPage?.title}</h4>
          <Button to={`/contact`} css={css`
            display: none;
            ${mobile}{
              display: block;
            }
          `}>Contact Us</Button>
        </div>
        <div css={css`
          grid-column: 3/13;
          ${mobile}{
            grid-column: span 12;
          }
        `}>
          {showrooms?.map(showroom => (
              <LocationBlock 
                key={showroom?._key}
                title={showroom?.title}
                address={showroom?.address}
                city={showroom?.city}
                state={showroom?.state}
                postCode={showroom?.postCode}
                country={showroom?.country}
                phoneNumber={showroom?.phoneNumber}
                openingHours={showroom?.openingHours} 
                parkingInfo={showroom?.parkingInfo} 
                image={showroom?.image}
								secondaryImage={showroom?.secondaryImage}
                lat={showroom?.lat}
                lng={showroom?.lng}
                mapUrl={showroom?.mapUrl}
              />
            ))}
          </div>
      </Section>
      <CTASection
        image={showroomPage._rawContent?.cta?.image}
        title={showroomPage._rawContent?.cta?.title}
				text={showroomPage._rawContent?.cta?.text} 
        link={resolveLink(showroomPage._rawContent?.cta?.link?.to?._type, showroomPage._rawContent?.cta?.link?.to?.slug?.current)}
        buttonText={showroomPage._rawContent?.cta?.link?.linkText}
      />
      <div css={css`height: 58px; margin-bottom: -58px;`} />
    </div>
  )
}

export const query = graphql`
  query {
    sanityShowrooms {
      _rawContent(resolveReferences: {maxDepth: 10})
      title
      content {
        seo {
          metaTitle
          metaDescription
          socialImage {
            asset {
              url
            }
          }
        }
      }
    }
  }
`

export default Showrooms
