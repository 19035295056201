import React from 'react'
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { breakpoints } from '~styles/global'
import Map from '../../Map';
import Image from '~components/Image';
import { paramCase } from "change-case"

const LocationBlock = ({ className, title, address, city, state, postCode, country, phoneNumber, openingHours, parkingInfo, image, lat, lng, mapUrl, secondaryImage}) => {

  const {mobile} = breakpoints

  return (
    <div 
      id={paramCase(title)}
      className={className} 
      css={css`
        display: grid;
        grid-template-columns: repeat(10, 1fr);
        column-gap: 20px;
        margin-bottom: 278px;
        ${mobile}{
          grid-template-columns: repeat(12, 1fr);
          column-gap: 10px;
          margin-bottom: 150px;
      }
    `}>
      <div 
        css={css`
          display: grid;
          grid-column: 1/5;
          ${mobile}{
            grid-column: span 12;
          }
      `}>
        <h4 css={css`
          margin-bottom: 36px;
          word-spacing: 999999px;
        `}>{title}</h4>
        <div css={css`
          align-self: end;
        `}>
          <p
            className="h6"
            css={css`
              padding-bottom: 44px;
              ${mobile}{
                padding-bottom: 40px;
              }
            `}
          >
            <a href={mapUrl} target="blank">
              {address}<br/>
              {city}&nbsp;
              {state}&nbsp;
              {postCode}<br/>
              {country}<br/>
              {phoneNumber}
            </a>
          </p>
          <div
            className="h6"
            css={css`
              display: grid;
              grid-template-columns: repeat(4, 1fr);
              margin-bottom: 42px;
              ${mobile}{
                margin-bottom: 40px;
                grid-template-columns: 1fr 1fr;
              }
          `}>
            {openingHours.map((openingHour, index) =>(
              <React.Fragment key={openingHour._key}>
                <div 
                  css={css`
                    grid-column: 1/3;
                    grid-row: ${index + 1};
                    ${mobile}{
                      grid-column: span 1;
                    }
                `}>
                  {openingHour.days}
                </div>
                <div css={css`
                  grid-column: 3/5;
                  grid-row: ${index + 1};
                  ${mobile}{
                    grid-column: span 1;
                  }
                `}>
                  {openingHour.hours}
                </div>
              </React.Fragment>
            ))}
          </div>
          <div
            className="h6"
            css={css`
              margin-bottom: 80px;
              ${mobile}{
                margin-bottom: 60px;
                max-width: 84%;
              }
            `}
          >
            Parking
            <p>{parkingInfo}</p>
          </div>
        </div>
      </div>
      <div css={css`
        grid-column: 6/11;
        ${mobile}{
          grid-column: span 12;
        }
      `}>
        <div css={css`
          margin-bottom: 60px;
        `}>
          <Image
            asset={image}
            aspectRatio={1.73}
          />
        </div>
        {/* <Map lat={lat} lng={lng} /> */}
      </div>
			<div css={css`
        grid-column: 6/11;
        ${mobile}{
          grid-column: span 12;
        }
      `}>
			{secondaryImage &&
				<Image
            asset={secondaryImage}
            aspectRatio={1.73}
          />
				
				}
			</div>
    </div>
  )
}

LocationBlock.propTypes = {
  title: PropTypes.string,
  address: PropTypes.string,
  phoneNumber: PropTypes.string,
  openingHours: PropTypes.arrayOf(PropTypes.shape({
    days: PropTypes.string,
    hours: PropTypes.string,
  })),
  parkingInfo: PropTypes.string,
  image: PropTypes.object,
  lat: PropTypes.number,
  lng: PropTypes.number,
}

LocationBlock.defaultProps = {
  title: `Sydney Showroom`,
  address: `840 Bourke Street`,
  city: `Waterloo`,
  state: `NSW`,
  postCode: 2017,
  country: `Australia`,
  phoneNumber: `+61 2 8399 5630`,
  openingHours: [
    {days: `Monday - tuesday`, hours: `closed`},
    {days: `Wednesday - Saturday`, hours: `10am - 5pm`},
    {days: `Sunday`, hours: `11am - 4pm`}
  ],
  parkingInfo: `Street parking is available or alternatively we can offer you private parking. Please call the showroom upon arrival.`,
  lat: -37.7991804,
  lng: 144.9862759,
}

export default LocationBlock
